import styled from 'styled-components';

export const Container = styled.a`
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 999;

  & button,
  & button:hover {
    background-color: #37d043;
  }

  & button img {
    width: 24px;
  }
`;
