import React from 'react';
import styled from 'styled-components';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Whatsapp from '../../components/Whatsapp';
import { mediaQuerySmall } from '../../helper';
import '../../styles/global.css';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & .children {
    flex-grow: 1;
  }

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 24px 64px 0;
    margin-bottom: 24px;
  }

  & .header .logo {
    width: 124px;
    margin-right: 32px
  }

  & .action-button {
    padding: 12px 32px;
    border-radius: 4px;
    background-color: #eb972a;
    color: #ffffff;
  }

  ${mediaQuerySmall} {
    & .header {
      padding: 24px 24px 0;
    }
  }
`;

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Mulish', 'Helvetica Neue', 'Arial'].join(','),
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#EB972A',
    },
    secondary: {
      main: '#E3F1DF',
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#f44336',
    },
    info: {
      main: '#2196f3',
    },
    warning: {
      main: '#ff9800',
    },
  },
});

export default function MainLayout({ children, hideTopbar }) {
  return (
    <MuiThemeProvider theme={theme}>
      <Content>
        <Helmet
          meta={[
            {
              name: `description`,
              content: 'La fisioterapia es mucho más que un masaje. Es de gran importancia atender y cuidar nuestro cuerpo. Atiende tanto tus lesiones recientes como pasadas',
            },
            {
              property: `og:title`,
              content: 'Gofisiomx - Fisioterapia en clínica y a domicilio',
            },
            {
              property: `og:description`,
              content: 'La fisioterapia es mucho más que un masaje. Es de gran importancia atender y cuidar nuestro cuerpo. Atiende tanto tus lesiones recientes como pasadas',
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:locale`,
              content: `es_MX`,
            },
            {
              property: `og:image`,
              content: `https://gofisiomx.com/1200x630.jpg`,
            },
          ]}
        >
          <title>Gofisiomx</title>
        </Helmet>
        {/*<Header />*/}
        {!hideTopbar && (
          <div className="header">
            <Link to="/">
              <img className="logo" src="/gofisio-logo.png" alt="Gofisiomx logo" />
            </Link>
            <a className="action-button" href="tel:6671620294">
              Llama ya
            </a>
          </div>
        )}
        <div className="children">
          {children}
        </div>
        <Footer />
        <Whatsapp />
      </Content>
    </MuiThemeProvider>
  );
}
