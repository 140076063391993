import React from 'react';
import Fab from '@material-ui/core/Fab';

import { Container } from './styles';

export default function Whatsapp() {
  return (
    <Container
      href="https://api.whatsapp.com/send?phone=5216671620294"
      rel="noreferrer"
      target="_blank"
    >
      <Fab size="medium" color="inherit">
        <img src="/whatsapp.svg" alt="whatsapp" />
      </Fab>
    </Container>
  );
}
