import React from 'react';
import { Link } from 'gatsby';

import FacebookIcon from '@material-ui/icons/Facebook';

import { Container } from './styles';

export default function Footer() {
  return (
    <Container>
      <div className="links-section">
        <div>
          <Link to="/">
            <img className="logo" src="/gofisio-logo.png" alt="Gofisiomx logo" />
          </Link>
        </div>
        <div>
          <div className="title">Mapa del sitio</div>
          <div className="option">
            <Link to="/#especialidades">Especialidades</Link>
          </div>
          <div className="option">
            <Link to="/#servicios">Servicios</Link>
          </div>
          <div className="option">
            <Link to="/blog">Blog</Link>
          </div>
          <div className="option">
            <Link to="/#equipo">Equipo</Link>
          </div>
          <div className="option">
            <Link to="/#ubicacion">Ubicación</Link>
          </div>
          <div className="option">
            <Link to="https://www.instagram.com/gofisioshopmx/" target="_blank">
              Tienda en línea
            </Link>
          </div>
        </div>
        <div>
          <div className="title">Contacto</div>
          <div className="option">
            <a href="tel:6671620294">667 162 0294</a>
          </div>
          <div className="option">
            <a href="mailto:fisioterapia.pablosanchez@gmail.com">fisioterapia.pablosanchez@gmail.com</a>
          </div>
        </div>
        <div>
          <div className="title">Redes sociales</div>
          <div className="social-networking">
            <a href="https://www.instagram.com/gofisiomx/" target="_blank" rel="noopener noreferrer">
              <img alt="Instagram" src="/images/instagram-blue.svg" />
            </a>
            <a href="https://www.facebook.com/gofisiocln/" target="_blank" rel="noopener noreferrer">
              <img alt="Linkedin" src="/images/facebook-blue.svg" />
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
}
