import styled from 'styled-components';
import { mediaQuerySmall, mediaQueryMedium } from '../../helper';

export const Container = styled.footer`
  padding: 48px 64px 72px 100px;
  background: #ffffff;
  color: #000000;

  & .logo {
    width: 160px;
  }

  & .links-section {
    display: flex;
    justify-content: space-between;
    max-width: 840px;
  }

  & .links-section .title {
    font-size: 18px;
    font-weight: bold;
  }

  & .links-section .title a {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
  }

  & .links-section .option {
    margin-top: 16px;
    font-size: 16px;
  }

  & .links-section .option a {
    color: #000000;
  }

  & .links-section .bottom {
    margin-top: 32px;
  }

  & .social-networking {
    margin-top: 16px;
    display: flex;
    align-items: center;
  }

  & .social-networking a {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  & .social-networking a:last-child {
    margin-left: 16px;
  }

  & .social-networking svg {
    cursor: pointer;
    color: #000000;
  }

  & .responsive {
    display: none;
  }

  ${mediaQueryMedium} {
    padding: 48px 40px 72px;
  }

  ${mediaQuerySmall} {
    padding: 48px 24px 72px;
    & .links-section {
      flex-direction: column;
    }

    & .desktop {
      display: none;
    }

    & .title:not(:first-child) {
      margin-top: 16px;
    }

    & .links-section > div:not(:first-child) {
      margin-top: 40px;
    }

    & .responsive {
      display: block;
    }
  }
`;
