import styled from 'styled-components';

export const Container = styled.header`
  padding: 16px 32px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
  z-index: 9;

  & button {
    font-size: 16px;
  }

  & .logo {
    width: 168px;
    object-fit: cover;
  }

  & .right-content nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & .right-content nav a, & .right-content nav .a {
    margin-right: 32px;
    cursor: pointer;
    color: #000;
    transition: color 0.25s;
    font-size: 16px;
  }

  & .right-content nav a:hover, & .right-content nav .a:hover {
    color: #3CBFDA;
  }

  & .telephone-section > svg {
    margin-right: 8px;
  }

  & .telephone-section {
    display: flex;
    align-items: center;
    color: #3CBFDA !important;
  }

  & .responsive {
    display: none;
  }

  & .cotizar {
    border-radius: 20px;
  }

  @media (max-width: 1040px) {
    max-width: 100vw;
    padding: 16px;
    align-items: center;

    & .right-content {
      display: none;
    }

    & .logo {
      width: 140px;
    }

    & .responsive {
      display: block;
    }
  }
`;

export const A = styled.div`
  cursor: pointer;
  color: #000;
  transition: color 0.25s;
  font-size: 14px;
  with: 100%;
  height: 100%;

  &:hover {
    color: #3CBFDA;
  }
`;

export const ContentDrawer = styled.div`
  background: white;
  padding: 24px;
  min-width: 240px;

  & nav {
    display: flex;
    flex-direction: column;
  }

  & nav a, & nav .a {
    cursor: pointer;
    color: #000;
    transition: color 0.25s;
    font-size: 18px;
    margin-bottom: 24px;
  }

  & nav a:hover, & nav .a:hover {
    color: #3CBFDA;
  }

  & .telephone-section a {
    display: flex;
    align-items: center;
    color: #3CBFDA;
  }
`;
